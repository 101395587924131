<template>
  <div class="rich-preview">
    <div
      ref="content"
      class="content"
      v-html="html"
      @click="onElementClick"
    ></div>
  </div>
</template>
<script>
import { imgDecyptApi } from "@/api/app";
import placeholderImage from "@/assets/png/place.png";
import { jumpAdv } from "@/utils/getConfig";
import { mapGetters } from "vuex";
const observedElements = [];
let observer = null;
const isFullURL = (text) => {
  return /^(http(s)?:\/\/)\w+[^\s]+(\.[^\s]+){1,}$/.test(text);
};
export default {
  props: {
    text: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      imgApi: "imgApi",
    }),
    html() {
      this.$nextTick(this.handleDynamicElements);
      return this.replaceImageSrc(this.text || "");
    },
  },
  methods: {
    handleDynamicElements() {
      const imgs = this.$refs.content.getElementsByTagName("img");
      this.observeImages(imgs);
    },
    onElementClick(e) {
      const ele = e.target;
      const aTag = this.findNearestElement("a", ele);
      if (aTag) {
        const href = aTag.getAttribute("href");
        jumpAdv({ href });
      }
    },
    findNearestElement(nodeName, element) {
      const upperNodeName = nodeName.toUpperCase();
      if (!element) return null;
      if (element.nodeName === upperNodeName) return element;
      return this.findNearestElement(upperNodeName, element.parentElement);
    },
    observeImages(imgs) {
      // 先移除原来的监听
      this.unobserveImages();
      if (!imgs) return;
      for (let index = 0; index < imgs.length; index++) {
        const element = imgs[index];
        observer.observe(element);
        observedElements.push(element);
      }
    },
    unobserveImages() {
      observedElements.forEach((v) => observer.unobserve(v));
      observedElements.splice(0);
    },
    unobserveImage(img) {
      observer.unobserve(img);
      const idx = observedElements.indexOf(img);
      if (idx >= 0) {
        observedElements.splice(idx, 1);
      }
    },
    unobserveEntry(entry) {
      this.unobserveImage(entry.target);
    },
    replaceImageSrc(htmlContent) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, "text/html");
      const images = doc.getElementsByTagName("img");
      for (const img of Array.from(images)) {
        let imgSrc = img.getAttribute("src") || "";
        img.setAttribute("src", placeholderImage);
        img.setAttribute("temp-src", imgSrc);
      }
      return doc.documentElement.outerHTML;
    },
    async observeEntry(entry) {
      const { intersectionRatio } = entry;
      if (intersectionRatio <= 0) return;
      const target = entry.target;
      const tempSrc = target.getAttribute("temp-src");
      if (!tempSrc) {
        // 没有临时路径，清除监听
        this.unobserveEntry(entry);
        return;
      }
      // 是完整url，无需解密直接src加载
      if (isFullURL(tempSrc)) {
        target.style.minHeight = target.style.minHeight || "auto";
        target.setAttribute("src", tempSrc);
        target.removeAttribute("temp-src");
        this.unobserveEntry(entry);
        return;
      }
      try {
        let imgApi = this.imgApi;
        if (imgApi.slice(-1) != "/") {
          imgApi = imgApi + "/";
        }
        const url = await imgDecyptApi(imgApi + tempSrc);
        target.style.minHeight = target.style.minHeight || "auto";
        target.setAttribute("src", url);
        target.removeAttribute("temp-src");
        this.unobserveEntry(entry);
      } catch (error) {
        console.error("img decypt error:", error);
      }
    },
  },
  created() {
    observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.observeEntry(entry);
        });
      },
      { threshold: 0.1 }
    );
  },
  beforeDestroy() {
    this.unobserveImages();
    observer.disconnect();
  },
};
</script>
<style lang="scss" scoped>
.rich-preview {
  .content {
    margin: 0 0 10px 0;
    color: #888;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 400;
    white-space: pre-line;

    /deep/ img {
      width: 100%;
      min-height: 200px;
      object-fit: scale-down;
    }
  }
}
</style>
