import { render, staticRenderFns } from "./TransverselyTwoList.vue?vue&type=template&id=3cf57c2c&scoped=true"
import script from "./TransverselyTwoList.vue?vue&type=script&lang=js"
export * from "./TransverselyTwoList.vue?vue&type=script&lang=js"
import style0 from "./TransverselyTwoList.vue?vue&type=style&index=0&id=3cf57c2c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cf57c2c",
  null
  
)

export default component.exports