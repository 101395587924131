<template>
    <div class="postAtlas" :class="{ isPostDetails: hiddenOtp }">
        <div class="communityHead">
            <div class="userContent">
                <div class="portrait" @click.stop="jumpUserHome(postInfo.publisher)">
                    <ImgDecypt class="avatarImg" :src="postInfo.publisher.portrait" round>
                        <img v-if="(postInfo.publisher.officialCertExpire && new Date(postInfo.publisher.officialCertExpire).getTime() > new Date().getTime()) || (postInfo.publisher.superUserExpire && new Date(postInfo.publisher.superUserExpire).getTime() > new Date().getTime())"
                            class="authIcon" src="@/assets/png/authenticationIcon.png">
                    </ImgDecypt>
                </div>
                <div class="userInfoBox">
                    <div class="userInfo">
                        <div class="userInfoLeft">
                            <div class="userName">
                                <span>{{ postInfo.publisher.name }}</span>
                                <!--                                <span class="supreme"-->
                                <!--                                      v-if="postInfo.publisher.videoFreeExpiration && new Date(postInfo.publisher.videoFreeExpiration).getTime() > new Date().getTime()">至尊会员</span>-->
                                <!--                                <img v-if="postInfo.publisher.vipLevel == 1" class="vip"-->
                                <!--                                     src="@/assets/png/userVip.png">-->
                                <img v-if="postInfo.publisher.isVip" class="vip" src="@/assets/png/userVip.png">
                                <!--            <span class="merchant"-->
                                <!--              v-if="postInfo.publisher.officialCertExpire && new Date(postInfo.publisher.officialCertExpire).getTime() > new Date().getTime()">认证商家</span>-->
                                <!--            <span class="blogger"-->
                                <!--              v-if="postInfo.publisher.superUserExpire && new Date(postInfo.publisher.superUserExpire).getTime() > new Date().getTime()">认证博主</span>-->
                                <!--            <span class="topCommunity" v-if="postInfo.isTopping">置顶</span>-->
                            </div>
                            <div class="releaseTime">
                                <img class="sexIcon"
                                    v-if="postInfo.publisher.gender && postInfo.publisher.gender == 'male'"
                                    src="@/assets/png/maleIcon.png">
                                <img class="sexIcon"
                                    v-else-if="postInfo.publisher.gender && postInfo.publisher.gender == 'female'"
                                    src="@/assets/png/femaleIcon.png">
                                <span>{{ postInfo.reviewAt | newTime }}</span>
                            </div>
                        </div>

                        <div class="userInfoRight">
                            <div class="focusBtn" v-if="!postInfo.publisher.hasFollowed"
                                @click.stop="focus(postInfo.publisher)">
                                <svg-icon icon-class="btnAdd"></svg-icon>
                                <span>关注</span>
                            </div>
                            <div class="alreadyFocus" v-else @click.stop="focus(postInfo.publisher)">
                                <span>已关注</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--          <div class="focusBtn" v-if="!postInfo.publisher.hasFollowed" @click.stop="focus(postInfo.publisher)">-->
            <!--              <svg-icon icon-class="btnAdd"></svg-icon>-->
            <!--              <span>关注</span>-->
            <!--          </div>-->
            <!--          <div class="alreadyFocus" v-else @click.stop="focus(postInfo.publisher)">-->
            <!--              <span>已关注</span>-->
            <!--          </div>-->
        </div>

        <TextOverflow :text="postInfo.title" :maxLines="4" :isPostItem="true" :postInfo="postInfo">
            <template v-slot:default="{ clickToggle, expanded }">
                <div @click.stop="clickToggle" class="text-btn">
                    {{ expanded ? "收起" : "全文" }}
                </div>
            </template>
        </TextOverflow>

        <div class="tagBox">
            <div class="tagItem" v-for="tagItem in postInfo.tags" :key="tagItem.id" @click.stop="tagJump(tagItem)">
                <svg-icon icon-class="tagIcon" class="tagIcon"></svg-icon>
                {{ tagItem.name }}
            </div>
        </div>

        <div class="divisionLine"></div>

        <RichPreview v-if="postInfo.richText" :text="postInfo.richText"></RichPreview>

        <TextOverflow v-else :text="postInfo.content" :maxLines="4" :isPostItem="true" :postInfo="postInfo">
            <template v-slot:default="{ clickToggle, expanded }">
                <div @click.stop="clickToggle" class="text-btn">
                    {{ expanded ? "收起" : "全文" }}
                </div>
            </template>
        </TextOverflow>

        <div class="imgList">
            <!--            <div v-for="(i,j) in postInfo.seriesCover.slice(0,9)" :key="i" @click.stop="previewImg(j)">-->
            <!--&lt;!&ndash;            <div v-for="(i,j) in postInfo.seriesCover.slice(0,6)" :key="i">&ndash;&gt;-->
            <!--                <ImgDecypt class="imgItem" :src="i" :key="i">-->
            <!--                    <div class="maskNum" v-if="j == 5 && postInfo.seriesCover.length > 6">-->
            <!--                        +{{ postInfo.seriesCover.length - 6 }}-->
            <!--                    </div>-->
            <!--                </ImgDecypt>-->
            <!--            </div>-->
            <!--            <div v-if="postInfo.newsType == 'COVER_SP'">-->
            <template v-if="!postInfo.richText">
                <div v-if="postInfo.sourceURL != ''">
                    <div @click.stop="previewImg(postInfo.seriesCover.slice(1), index)"
                        v-for="(src, index) in postInfo.seriesCover.slice(1)" :key="src + index">
                        <ImgDecypt :src="src" class="img_item"></ImgDecypt>
                    </div>
                </div>
                <div v-else-if="postInfo.originCoins > 0">
                    <div @click.stop="previewImg(postInfo.vidStatus.hasPaid ? postInfo.seriesCover : postInfo.seriesCover.slice(0, 5), index)"
                        v-for="(src, index) in postInfo.vidStatus.hasPaid ? postInfo.seriesCover : postInfo.seriesCover.slice(0, 5)"
                        :key="src + index">
                        <ImgDecypt :src="src" class="img_item" />
                    </div>
                </div>
                <div v-else-if="postInfo.originCoins == 0">
                    <div @click.stop="previewImg(userInfo.isVip ? postInfo.seriesCover : postInfo.seriesCover.slice(0, 5), index)"
                        v-for="(src, index) in userInfo.isVip ? postInfo.seriesCover : postInfo.seriesCover.slice(0, 5)"
                        :key="src + index">
                        <ImgDecypt :src="src" class="img_item" />
                    </div>
                </div>

            </template>

            <div class="post_video" @click="playVideo" v-if="
                postInfo.sourceURL != ''
            ">
                <ImgDecypt :src="postInfo.cover" :key="postInfo.cover"></ImgDecypt>
                <div class="play_box">
                    <!--                    <SvgIcon name="playIcon" />-->
                    <img class="playBtn" src="@/assets/png/playBtn.png" />
                </div>
            </div>

            <!--            <div-->
            <!--                class="post_video"-->
            <!--                @click.stop="openVideo"-->
            <!--                v-if="-->
            <!--          !(postInfo.originCoins > 0 && !postInfo.vidStatus.hasPaid && !postInfo.freeArea) &&-->
            <!--          !(postInfo.originCoins === 0 && !userInfo.isVip) &&-->
            <!--          postInfo.sourceURL != null-->
            <!--        "-->
            <!--            >-->
            <!--                <ImgDecypt :src="postInfo.cover" :key="postInfo.cover"></ImgDecypt>-->
            <!--                <div class="play_box">-->
            <!--                    &lt;!&ndash;                    <SvgIcon name="playIcon" />&ndash;&gt;-->
            <!--                    <img class="playBtn" src="@/assets/png/playBtn.png"/>-->
            <!--                </div>-->
            <!--            </div>-->
            <template v-if="!postInfo.richText">
                <div class="unlockBox" v-if="
                    postInfo.seriesCover.length > 5 &&
                    postInfo.originCoins === 0 &&
                    !postInfo.freeArea &&
                    !userInfo.isVip &&
                    postInfo.sourceURL == ''
                ">
                    <ImgDecypt :src="postInfo.seriesCover[5]" :key="postInfo.seriesCover[5] + 5" class="unlockImg" />
                    <!--              @click.stop="previewImg(5)"-->
                    <div class="unlockContent">
                        <div class="title_box">
                            <span>解锁即可继续查看 {{ postInfo.seriesCover.length - 5 }}张</span>
                        </div>
                        <div class="about-promote">
                            <!--            <div class="sub-title">推广赚钱最高返利75%</div>-->
                            <p>成功推广好友 立即获得会员权益</p>
                            <!--            <div class="tip">您的观看次数不足</div>-->
                        </div>
    
                        <div class="btnGroup">
                            <div @click.stop="$router.push('/sharePromotion')" class="onlineBtn">邀请好友</div>
                            <div @click.stop="$router.push('/rechargePage?t=vip')" class="sendgiftsBtn">购买会员</div>
                            <!--                   <img @click.stop="$router.push('/promotionCode')" class="onlineBtn" src="@/assets/images/png/inviteBtn.png">-->
                            <!--                   <img  @click.stop="joinMembership" class="sendgiftsBtn" src="@/assets/images/png/buyVipBtn.png">-->
                        </div>
                    </div>
                </div>
                <div class="unlockBox" v-else-if="
                    postInfo.seriesCover.length > 5 &&
                    postInfo.originCoins > 0 &&
                    !postInfo.vidStatus.hasPaid &&
                    !postInfo.freeArea &&
                    postInfo.sourceURL == ''
                ">
                    <ImgDecypt :src="postInfo.seriesCover[5]" :key="postInfo.seriesCover[5] + 5" class="unlockImg" />
                    <!--              @click.stop="previewImg(5)"-->
                    <div class="unlockContent">
                        <!--          <div class="title_box">-->
                        <!--            <span>解锁即可继续查看 {{ props.postInfo.seriesCover.length - 5 }}张</span>-->
                        <!--          </div>-->
                        <div class="title_box">
                            <span>
                                您只需花费
                                <span class="amount">{{ userInfo.isVip ? postInfo.coins : postInfo.originCoins }}</span>
                                金币
                            </span>
                        </div>
                        <div class="tip">即可解锁继续查看</div>
    
                        <div class="btnGroup">
                            <div class="sendgiftsBtn" @click.stop="unlockNowCoupon">立即解锁</div>
                        </div>
                    </div>
                </div>
            </template>

        </div>

        <!--        <div v-else-if="postInfo.newsType == 'SP'" class="videoContent" @click="playVideo">-->
        <!--            <div class="mask">-->
        <!--                <div class="playTime">-->
        <!--                    {{ postInfo.playTime | videotime }}-->
        <!--                </div>-->
        <!--            </div>-->
        <!--            <img class="playBtn" src="@/assets/png/playBtn.png"/>-->
        <!--            <ImgDecypt class="videoImg" :src="postInfo.cover"/>-->
        <!--        </div>-->

        <div class="postMenu">

            <div class="menuItme" @click.stop="like">
                <svg-icon class="greyHeart"
                    :icon-class="postInfo.vidStatus.hasLiked ? 'redHeart' : 'grey_heart'"></svg-icon>
                <div class="menuNum">{{ postInfo.likeCount | watchCount }}</div>
            </div>

            <div class="menuItme" @click.stop="reward">
                <div class="rewardCoin" :class="{ rewardedCoin: parseInt(postInfo.rewarded) > 0 }"></div>
                <div class="menuNum" v-if="parseInt(postInfo.rewarded) > 0">{{ parseInt(postInfo.rewarded) | watchCount
                    }}</div>
                <div v-else>打赏</div>
            </div>

            <div class="menuItme" @click.stop="comment">
                <div class="greyMessage"></div>
                <!--                <svg-icon icon-class="greyMessage" class="greyMessage"></svg-icon>-->
                <div class="menuNum">{{ postInfo.commentCount | watchCount }}</div>
            </div>

            <!--                        <div class="menuItme">-->
            <!--                            <div class="fireIcon"></div>-->
            <!--                            <div class="menuNum">{{ postInfo.playCount | watchCount }}</div>-->
            <!--                        </div>-->
            <div class="menuItme" @click.stop="openVipPopup(postInfo)">
                <div class="shareIcon"></div>
                <!--                <svg-icon icon-class="shareIcon" class="shareIcon"></svg-icon>-->
                <div class="menuNum">分享</div>
            </div>

        </div>

        <div class="bottomLine"></div>
    </div>
</template>

<script>
import RichPreview from "@/components/RichPreview";
import TextOverflow from "@/components/TextOverflow";
import ImgDecypt from "@/components/ImgDecypt";
import { cancleZan, careOrcancle, zan } from "@/api/user";
import {
    setSessionItem
} from "@/utils/longStorage";
import {
    videoBuying,
} from "@/api/video"
import {
    Toast
} from "vant";
import {
    mapGetters
} from "vuex";
import { queryHotspotWonderList } from "@/api/community";

export default {
    components: {
        ImgDecypt,
        TextOverflow,
        RichPreview
        // Forward,
    },
    props: {
        postInfo: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
        isCreateCenter: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        isShowHead: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        hiddenOtp: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo'
        }),
    },
    data() {
        return {}
    },
    created() {
        //   console.log(this.postInfo)
    },
    methods: {
        openVipPopup(postInfo) {
            this.$bus.$emit("openSharePopup", postInfo);
        },
        // 跳转用户主页
        jumpUserHome(item) {
            this.$router.push({
                path: "/userHomePage",
                query: {
                    uid: item.uid
                }
            })
        },
        //点赞
        async like() {
            let isLike = this.postInfo?.vidStatus?.hasLiked;
            let id = this.postInfo.id;
            let req = {
                objID: id,
                type: this.postInfo.newsType == "COVER" ? "image" : "video",
            };
            try {
                if (isLike) {
                    let res = await this.$Api(cancleZan, req);
                    if (res.code === 200) {
                        this.postInfo.vidStatus.hasLiked = false;
                        this.postInfo.likeCount--;
                        Toast("取消点赞");
                        return;
                    }
                    Toast(res.tip || "取消失败");
                } else {
                    let res = await this.$Api(zan, req);
                    if (res.code === 200) {
                        this.postInfo.vidStatus.hasLiked = true;
                        this.postInfo.likeCount++;
                        Toast("点赞成功");
                        return;
                    }
                    Toast(res.tip || "点赞失败");
                }
            } catch (e) {
                Toast("操作失败");
            }
        },
        // 帖子详情
        details(item) {
            // if(item.sourceURL){
            //   this.playVideo(item);
            //   return;
            // }
            this.$router.push({
                path: "/postDetails",
                query: {
                    videoID: item.id
                },
            })
            // if (item?.vidStatus?.hasPaid) {
            //   this.$router.push({
            //     path:"/postDetails",
            //     query:{
            //       videoID: item.id
            //     },
            //   })
            // } else if (item.coins > 0) {
            //   this.$bus.$emit("vipPopup", {
            //     id: this.postInfo.id,
            //     state: 2,
            //     goldenNum: item.coins,
            //     closeBtn: () => {
            //       // console.log(123123)
            //       this.$bus.$emit("closeVipPopup");
            //     },
            //     buySuccess: () => {
            //       item.vidStatus.hasPaid = true;
            //       this.$bus.$emit("closeVipPopup");
            //       // console.log(123123)
            //     },
            //   });
            // } else if (this.userInfo.isVip) {
            //   /**
            //    * 图片预览
            //    */
            //    this.$router.push({
            //     path:"/postDetails",
            //     query:{
            //       videoID: item.id
            //     }
            //   })
            // } else {
            //   this.$bus.$emit("vipPopup", {
            //     id: this.postInfo.id,
            //     state: 1,
            //     goldenNum: this.postInfo.coins,
            //     closeBtn: () => {
            //       this.$bus.$emit("closeVipPopup");
            //     },
            //     buySuccess: () => {
            //       item.vidStatus.hasPaid = true;
            //       this.$bus.$emit("closeVipPopup");
            //       // console.log(123123)
            //     },
            //   });
            // }
        },
        // 话题跳转
        tagJump(item) {
            this.$router.push({
                path: "/topicDetails",
                query: {
                    id: item.id
                }
            })
        },
        // 关注/取消关注
        async focus(item) {
            let req = {
                followUID: item.uid,
                isFollow: !item.hasFollowed,
            }
            let res = await this.$Api(careOrcancle, req);

            if (res && res.code == 200) {
                if (!item.hasFollowed) {
                    item.hasFollowed = true;
                    this.$toast({
                        message: '关注成功',
                        position: 'top',
                    });
                } else {
                    item.hasFollowed = false;
                    this.$toast({
                        message: '取消关注',
                        position: 'top',
                    });
                }
                let focusData = {
                    focusSta: item.hasFollowed,
                    uid: item.uid,
                }
                this.$emit('focusOn', focusData);
            }
        },

        // 打赏
        reward() {
            this.$store.commit("user/SET_REWAE", {
                show: true,
                info: this.postInfo
            })
        },
        // 评论
        comment() {
            this.$store.commit("user/SET_COMMENTPOP", {
                show: true,
                info: this.postInfo
            })
        },
        // 删除
        delPost() {
            this.$emit("delPost", this.postInfo);
        },
        // 查看原因
        checkReview() {
            // let reason = encodeURIComponent(this.postInfo.reason)
            // console.log(encodeURIComponent(reason))
            let dataStr = encodeURI(JSON.stringify(this.postInfo));
            this.$router.push(`/reviewResult?data=${this.encodeBase64(dataStr)}`);
        },
        // 转base64
        encodeBase64(str) {
            let base64Str = Buffer.from(str, 'utf-8').toString('base64');
            return base64Str;
        },
        playVideo() {
            if (this.$route.path == '/postDetails') {
                setSessionItem("currentWorks", JSON.stringify(this.postInfo));
                this.$router.push({
                    path: "/shortVideo"
                })
            }
        },
        // 图片预览
        previewImg(list, index) {
            this.$router.push({
                name: "PicturePreview",
                params: {
                    imgs: list,
                    index: index
                },
            });
            // if (this.postInfo?.vidStatus?.hasPaid) {
            //     this.$router.push({
            //         name: "PicturePreview",
            //         params: {
            //             imgs: this.postInfo.seriesCover,
            //             index: index
            //         },
            //     });
            // } else if (this.postInfo.coins > 0) {
            //     this.$bus.$emit("vipPopup", {
            //         id: this.postInfo.id,
            //         state: 2,
            //         goldenNum: this.postInfo.coins,
            //         closeBtn: () => {
            //             // console.log(123123)
            //             this.$bus.$emit("closeVipPopup");
            //         },
            //         buySuccess: () => {
            //             this.postInfo.vidStatus.hasPaid = true;
            //             this.$bus.$emit("closeVipPopup");
            //         },
            //     });
            // } else if (this.userInfo.isVip) {
            //     /**
            //      * 图片预览
            //      */
            //     this.$router.push({
            //         name: "PicturePreview",
            //         params: {
            //             imgs: this.postInfo.seriesCover,
            //             index: index
            //         },
            //     });
            // } else {
            //     this.$bus.$emit("vipPopup", {
            //         id: this.postInfo.id,
            //         state: 1,
            //         goldenNum: this.postInfo.coins,
            //         closeBtn: () => {
            //             this.$bus.$emit("closeVipPopup");
            //         },
            //         buySuccess: () => {
            //             this.postInfo.vidStatus.hasPaid = true;
            //             this.$bus.$emit("closeVipPopup");
            //         },
            //     });
            // }
        },
        unlockNowCoupon() {
            let _this = this;
            _this.$bus.$emit("vipPopup", {
                id: this.postInfo.id,
                state: 2,
                goldenNum: _this.userInfo.isVip ? _this.postInfo.coins : _this.postInfo.originCoins,
                videoType: this.kind,
                buySuccess: () => {
                    /**
                     * 金币购买成功
                     */
                    _this.postInfo.vidStatus.hasPaid = true;
                    _this.$bus.$emit("closeVipPopup");
                    // _this.player.play();
                    // _this.getMediaInfoList();
                },
                closeBtn: () => {
                    _this.$bus.$emit("closeVipPopup");
                },
            });
            // this.unlockNow(0);
        }, async unlockNow(money) {
            this.$store.commit('app/SET_LOADING', true);
            let productID = this.postInfo.id;
            if (!productID) {
                this.$toast('购买出错~');
                return;
            }
            let req = {
                productType: 1,
                productID: productID,
                goldVideoCouponNum: 0,
                IsH5: true,
            }
            let res = await this.$Api(videoBuying, req);
            if (res.code == 200) {
                this.$toast('购买成功~');
                this.postInfo.vidStatus.hasPaid = true;
            } else {
                this.$toast(res.tip);
            }
            this.$store.commit('app/SET_LOADING', false);
        }
    }
}
</script>

<style lang="scss" scoped>
.postAtlas {
    color: #000;
    padding: 0 16px;
    box-sizing: border-box;
    // border-bottom: 1px solid #e6e6e6;

    .reviewStatus {
        margin-top: 18px;
        font-size: 15px;
    }

    .optBox {
        display: flex;
        align-items: center;

        .optBtn {
            font-size: 12px;
            padding: 4px 13px 3px;
            margin-right: 12px;
            border-radius: 50px;
            margin-top: 18px;
            box-sizing: border-box;
            background: #e6e6e6;
        }
    }

    .text-overflow {
        margin-top: 12px;
    }

    // margin-bottom: 40px;
    .imgList {
        //display: grid;
        //grid-template-columns: 1fr 1fr 1fr;
        //grid-row-gap: 3px;
        //grid-column-gap: 3px;
        margin: 12px 0;

        .imgItem {
            //width: 94px;
            //height: 94px;
            // background: #1b164c;
            position: relative;

            /deep/ img {
                // border-radius: 6px;
            }

            .maskNum {
                position: absolute;
                bottom: 4px;
                right: 4px;
                width: 40px;
                height: 20px;
                color: #F1F1F1;
                background: rgba(0, 0, 0, 0.60);
                border-radius: 8px;
                font-size: 12px;
                // border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .videoContent {
        margin: 9px 0;
        position: relative;

        // margin-left: 12px;
        .playBtn {
            width: 18px;
            height: 21px;
            position: absolute;
            z-index: 2;
            margin-top: 115px;
            margin-left: 80px;
            transform: translate(-50%, -50%);
        }

        .mask {
            width: 160px;
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            height: 60px;
            background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .9));
            z-index: 2;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }

        .playTime {
            // position: absolute;
            // z-index: 2;
            // margin-top: 205px;
            // margin-left: 115px;
            color: #fff;
            margin: 0 12px 9px 0;
        }

        .videoImg {
            width: 160px;
            height: 230px;

            /deep/ .van-image {
                // border-radius: 6px;
                background: #e6e6e600 !important;
            }

            /deep/ .van-image__img {
                // border-radius: 6px;
            }

            /deep/ .van-image__error {
                background-color: #e6e6e600 !important;
            }
        }
    }

    .divisionLine {
        width: 100%;
        height: 1px;
        background: #E6E6E6;
        margin: 18px 0;
    }

    .tagBox {
        display: flex;
        align-items: center;
        margin-top: 12px;
        flex-wrap: wrap;

        .tagItem {
            max-width: 69px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: rgba(51, 51, 51, 1);
            font-size: 12px;
            margin-right: 5px;
            padding: 2px 5px;
            border-radius: 16px;
            //background: rgba(148, 214, 218, 0.30);
            border: solid #E6E6E6 1px;
            margin-bottom: 5px;

            .tagIcon {
                width: 12px;
                height: 12px;
                margin-right: 2px;
            }
        }
    }

    .postMenu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 12px 0;

        .menuItme {
            font-size: 12px;
            color: #333333;
            display: flex;
            //flex-direction: column;
            align-items: center;
            justify-content: center;

            .menuNum {
                margin-left: 4px;
            }

            .fireIcon {
                width: 16px;
                height: 16px;
                background: url('../../assets/png/fire.png') no-repeat;
                background-size: 100% 100%;
                margin-bottom: 2px;
            }

            .greyHeart {
                width: 20px;
                height: 20px;
            }

            .redHeart {
                width: 20px;
                height: 20px;
            }

            .shareIcon {
                width: 20px;
                height: 20px;
                background: url('../../assets/png/shareIcon.png') no-repeat;
                background-size: 100% 100%;
            }

            .greyMessage {
                width: 20px;
                height: 20px;
                background: url('../../assets/png/greyMessage.png') no-repeat;
                background-size: 100% 100%;
            }

            .rewardCoin {
                width: 20px;
                height: 20px;
                background: url('../../assets/png/reward_coin.png') no-repeat;
                background-size: 100% 100%;
            }

            .rewardedCoin {
                width: 20px;
                height: 20px;
                background: url('../../assets/png/reward_coin.png') no-repeat;
                background-size: 100% 100%;
            }

            .greyMessage {
                width: 20px;
                height: 20px;
            }

            .shareIcon {
                width: 20px;
                height: 20px;
            }
        }
    }
}

.communityHead {
    // padding: 0 12px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .portrait {
        width: 44px;
        height: 44px;
        background: rgb(255, 255, 255);
        border-radius: 50%;
        flex-shrink: 0;
        position: relative;

        .authIcon {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 15px;
            height: 15px;
        }
    }

    .userContent {
        display: flex;
        align-items: start;

        .userInfoBox {
            width: calc(100vw - 87px);
            padding-left: 11px;

            .userInfo {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .userName {
                font-size: 15px;
                color: rgb(0, 0, 0);
                display: flex;
                align-items: center;

                .vip {
                    width: 30px;
                    height: 16px;
                    margin-left: 12px;
                }

                .topCommunity {
                    color: rgb(255, 255, 255);
                    font-size: 8px;
                    background: rgb(215, 65, 85);
                    border-radius: 15px;
                    padding: 2px 8px;
                    margin-left: 12px;
                }

                .merchant {
                    color: rgb(255, 255, 255);
                    font-size: 8px;
                    background: rgb(255, 0, 216);
                    border-radius: 15px;
                    padding: 2px 8px;
                    margin-left: 12px;
                }

                .blogger {
                    color: rgb(255, 255, 255);
                    font-size: 8px;
                    background: rgb(255, 139, 39);
                    border-radius: 15px;
                    padding: 2px 8px;
                    margin-left: 12px;
                }

                .supreme {
                    color: rgb(255, 255, 255);
                    font-size: 8px;
                    background: rgb(255, 69, 0);
                    border-radius: 15px;
                    padding: 2px 8px;
                    margin-left: 12px;
                }
            }

            .releaseTime {
                font-size: 12px;
                color: rgb(153, 153, 153);
                margin-top: 6px;

                display: flex;
                align-items: center;

                .sexIcon {
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }
        }
    }

    .btnIcon {
        width: 12px;
        height: 12px;
        margin-right: 4px;
    }

    .focusBtn {
        width: 60px;
        height: 24px;
        font-size: 12px;
        //padding: 3px 10px;
        //background: rgb(113, 69, 231);
        border-radius: 12.5px;
        color: #424242;
        background: #FBD13D;
        //border: solid #94D6DA 1px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .alreadyFocus {
        width: 60px;
        height: 24px;
        font-size: 12px;
        //padding: 3px 12px;
        background: #FAFAFA;
        border-radius: 12.5px;
        color: #CCCCCC;
        display: flex;
        align-items: center;
        justify-content: center;
        //border: solid #94D6DA 2px ;
    }


}

.bottomLine {
    width: 100%;
    height: 1px;
    background: #E6E6E6;
    margin: 18px 0;
}

.isPostDetails {
    border: none;
}


.unlockBox {
    width: 343px;
    height: 193px;

    .about-promote {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;

        .sub-title {
            font-size: 10px;
            color: #ffffff;
            font-weight: 500;
            margin-top: 10px;
        }

        p {
            font-size: 10px;
            color: #ffffff;
            margin-top: 10px;
            font-weight: 500;
        }

        .tip {
            font-size: 20px;
            color: rgb(255, 77, 0);
            margin-top: 13px;
        }

        .tip2 {
            font-size: 14px;
            color: rgb(255, 255, 255);
            margin-top: 10px;
        }
    }

    .unlockImg {
        width: 343px;
        height: 193px;
        position: absolute;
        filter: blur(5px);
        //-webkit-filter: blur(5px);
    }

    .unlockContent {
        width: 343px;
        height: 193px;
        //padding-top: 24px;
        position: relative;
        background: rgba(0, 0, 0, 0.6);
        text-align: center;
    }

    .title_box {
        text-align: center;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: bold;
        padding-top: 30px;

        .amount {
            font-size: 28px;
            color: #FDE39C;
        }
    }

    .line {
        margin: 12px auto 0;
        width: 95%;
        height: 1px;
        background: #ebebeb;
    }

    .about_author {
        padding: 14px 0;
        font-size: 14px;
        color: #666666;
        line-height: 22px;
        letter-spacing: 0.28px;
    }

    .amount_box {
        display: flex;
        justify-content: center;
        margin-top: 23px;

        .amount_item {
            width: 90px;
            height: 79px;
            border: 1px solid #a5a0a5;
            border-radius: 10px;
            margin: 0 5px;
            font-size: 20px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .amount_Box {
                border-radius: 10px;

                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .label {
                    text-align: center;
                    font-size: 12px;
                    color: rgb(218, 218, 218);
                    margin-bottom: 10px;
                }
            }

            svg {
                width: 23px;
                height: 23px;
                margin-right: 5px;
            }

            &.active {
                width: 110px;
                height: 79px;
                color: #ff7510;
                border: none;
                border-radius: 0px;
                //background-image: url('../../assets/images/png/vipPriceBorder.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                //background: linear-gradient(to left top, rgb(244, 84, 238), rgb(164, 255, 255));
            }
        }

        //.vipGold::after {
        //  content: '会员优惠';
        //}
    }

    .tip {
        font-size: 12px;
        color: rgb(255, 255, 255);
        margin-top: 26px;
    }

    .couponBox {
        font-size: 12px;
        color: #ffffff;
        margin-top: 25px;
        padding: 0 20px;

        .couponItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;

            .surplus {
                display: flex;
                align-items: center;

                .radio {
                    margin-left: 6px;
                }

                :deep(.van-icon-success:before) {
                    display: none;
                }
            }
        }
    }

    .btnGroup {
        //width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 18px;

        .onlineBtn {
            margin-right: 12px;
            width: 136px;
            height: 38px;
            background: #B3B3B3;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            color: #FFFFFF;
            font-size: 16px;
            font-weight: bold;
        }

        .sendgiftsBtn {
            width: 136px;
            height: 38px;
            background: #FBD13D;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            color: #333;
            font-size: 16px;
            font-weight: bold;
        }
    }
}

.post_video {
    height: 188px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    .play_box {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 40px;
            //height: 50px;
        }
    }
}
</style>
