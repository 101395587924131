var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hotRankingPage"},[_c('HeadBar',{attrs:{"titleName":"热门排行榜"}}),(_vm.navList && _vm.navList.length)?_c('van-tabs',{staticClass:"navTabs oepnX",attrs:{"swipeable":"","animated":"","line-width":"24","line-height":"6","background":"rgba(0,0,0,0)","color":"rgb(113,69,231)"},on:{"change":_vm.clickTag},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.navList),function(item,index){return _c('van-tab',{key:index,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"navTabTitle",class:{ navTabTitleActive: _vm.activeName == index }},[_vm._v(" "+_vm._s(item.title)+" ")])]},proxy:true}],null,true)},[_c('div',{staticClass:"hotRankingContent"},[(!_vm.isLoading)?_c('PullRefresh',{attrs:{"loading":_vm.loading,"refreshing":_vm.refreshing,"finished":_vm.finished,"isNoData":_vm.isNoData,"error":_vm.error,"is-higeht-max":true,"adv-list":_vm.advList,"hasAdvPagination":true,"className":"HotRankingPage"},on:{"onLoad":_vm.onLoad,"onRefresh":_vm.onRefresh}},[(_vm.activeName == 2)?_c('div',{staticClass:"mirrorContent"},_vm._l((_vm.videoList),function(item,index){return _c('div',{key:index,staticClass:"contentItemDiaphragm",on:{"click":function($event){return _vm.jump(item, index)}}},[_c('div',{staticClass:"videoImg",class:{
                  videoImgDiaphragm: _vm.activeName == 2 || _vm.activeName == 3,
                }},[_c('ImgDecypt',{attrs:{"src":item['horizontalCover']}}),_c('div',{staticClass:"mask_h"},[_c('div',{staticClass:"maskText",class:{ updateEnd: item.updateStatus == 2 }},[_vm._v(" "+_vm._s(item.updatedDesc)+" ")])])],1),_c('div',{staticClass:"videoInfo"},[_c('div',{staticClass:"videoTitle"},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"videoTag"},_vm._l((item['tagNames']),function(tagItem,tagIndex){return _c('span',{key:tagIndex,staticClass:"videoTxtTag",on:{"click":function($event){$event.stopPropagation();return _vm.tagJump(item, tagIndex, tagItem)}}},[_vm._v(" "+_vm._s(tagItem)+" ")])}),0)]),_c('div',{staticClass:"rankNum",class:index == 0
                    ? 'rankNum_1'
                    : index == 1
                    ? 'rankNum_2'
                    : index == 2
                    ? 'rankNum_3'
                    : index == 3},[_vm._v(" "+_vm._s(index > 2 ? index + 1 : "")+" ")])])}),0):_c('div',{staticClass:"OtherContent"},_vm._l((_vm.videoList),function(item,index){return _c('div',{key:index,staticClass:"contentItem",on:{"click":function($event){return _vm.jump(item, index)}}},[_c('div',{staticClass:"videoImg",class:{
                  videoImgDiaphragm: _vm.activeName == 2 || _vm.activeName == 3,
                }},[_c('ImgDecypt',{attrs:{"src":item['verticalCover']}}),_c('div',{staticClass:"mask_v"},[_c('div',{staticClass:"maskText",class:{ updateEnd: item.updateStatus == 2 }},[_vm._v(" "+_vm._s(item.updatedDesc)+" ")])])],1),_c('div',{staticClass:"videoInfo"},[_c('div',{staticClass:"videoTitle"},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"videoTag"},_vm._l((item['tagNames']),function(tagItem,tagIndex){return _c('span',{key:tagIndex,staticClass:"videoTxtTag",on:{"click":function($event){$event.stopPropagation();return _vm.tagJump(item, tagIndex, tagItem)}}},[_vm._v(" "+_vm._s(tagItem)+" ")])}),0)]),_c('div',{staticClass:"rankNum",class:index == 0
                    ? 'rankNum_1'
                    : index == 1
                    ? 'rankNum_2'
                    : index == 2
                    ? 'rankNum_3'
                    : index == 3},[_vm._v(" "+_vm._s(index > 2 ? index + 1 : "")+" ")])])}),0)]):_c('Loading')],1)])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }